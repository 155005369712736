<template>
  <div id="app">
    <div class="top">换/加餐
      <div class="top_l iconfont" @click="$router.push({path: '/bundle_calendar'})">&#xeac3;</div>
    </div>

    <!-- 选择餐费包 -->
    <div class="bg_green">
      <div class="white_box3 ">
        <!-- 标题 -->
        <div class="orange_title" style="font-weight: normal; font-size: 16px;">选择餐包</div>
        <div class="white_box3_in pdb_0">
          <div class="user under_line pd_3p6_0 clear" @click="showPicker = true">
            <span style=" font-weight: bold; font-size:13px;">{{ productBundle.text }}</span>
            <div class="user_r2 ico iconfont">&#xe602;</div>
          </div>
        </div>
        <van-popup v-model="showPicker" position="bottom">
          <van-picker show-toolbar :columns="productBundleOptions" @confirm="onConfirm"
                      @cancel="showPicker = false"/>
        </van-popup>
      </div>
    </div>

    <!-- 日期 -->
    <div class="white_box3 mgt_3vw">

      <!-- 标题 -->
      <div class="dark_title" style="font-weight: normal;">选择日期</div>

      <div class="white_box3_in pdb_0">
        <!-- 日期选择 -->
        <div class="user under_line pd_3p6_0 clear">
          <div class="user_msg">
            <div class="calendar_txb" @click="showCalendar1 = true">{{ dateValue }}</div>
          </div>
          <div class="user_r2" @click="showCalendar1 = true">
            <img src="../assets/images/ico_calendar.png" alt=""></div>
        </div>
      </div>
    </div>

    <!-- 换餐 -->
    <div class="white_box3 mgt_3vw" v-if="productBundle.remark">
      <!-- 标题 -->
      <div class="dark_title" style="font-weight: normal;">换餐</div>
      <div class="white_box3_in pdb_0">
        <div class="user under_line pd_3p6_0 clear">
          <div class="user_msg fw_bold">
            <span
                v-if="productBundle.changeCancelAdvance<=0">请于用餐日期的 当天{{
                productBundle.changeTimePoint ? productBundle.changeTimePoint.substring(0, 2) : ''
              }}点前 提交</span>
            <span
                v-if="productBundle.changeCancelAdvance>0">请于用餐日期的 前{{
                productBundle.changeCancelAdvance
              }}天{{
                productBundle.changeTimePoint ? productBundle.changeTimePoint.substring(0, 2) : ''
              }}点前 提交</span>
          </div>
        </div>
        <div class="user under_line pd_3p6_0 clear">
          <div class="user_msg">
            <span>{{ productBundle.remark }}</span>
          </div>
          <div class="user_r2">
            <van-checkbox v-model="checked" shape="square" checked-color="#34AC40"
                          icon-size="12px"></van-checkbox>
          </div>
        </div>
      </div>
    </div>

    <!-- 加餐 -->
    <div class="white_box3 mgt_3vw">

      <!-- 标题 -->
      <div class="dark_title" style="font-weight: normal;">加餐</div>

      <div class="white_box3_in pdb_0" v-if="extraMeal && extraMeal.length>0">
        <div class="user under_line pd_3p6_0 clear">
          <div class="user_msg fw_bold">
            <span
                v-if="productBundle.addAdvance<=0">请于用餐日期的 当天{{
                productBundle.addTimePoint ? productBundle.addTimePoint.substring(0, 2) : ''
              }}点前 提交</span>
            <span
                v-if="productBundle.addAdvance>0">请于用餐日期的 前{{
                productBundle.addAdvance
              }}天{{
                productBundle.addTimePoint ? productBundle.addTimePoint.substring(0, 2) : ''
              }}点前 提交</span>
          </div>
        </div>

        <!-- 数量选择 -->
        <div class="user under_line pd_3p6_0 clear" v-for="(option,index) in extraMeal" :key="index">
          <div class="user_msg">
            <div class="meals_pic_list_l" :style="{backgroundImage:`url(${img})`}"></div>
            <div class="meals_pic_list_r">
              <span>{{ option.name }}</span>
              <label>{{ option.price }}</label>
            </div>
          </div>
          <div class="user_r2">
            <van-stepper min="0" v-model="option.count"/>
          </div>
        </div>
      </div>
    </div>

    <button class="button_green large" @click="change">提交</button>

    <!-- 弹出开始日期 -->
    <van-calendar v-model="showCalendar1" @confirm="onConfirm1"/>

    <div class="loading_all" v-if="loading">     
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<style scoped>
::v-deep .van-checkbox {
  margin-left: 10px;
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_TOKEN, ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {formatDate} from '@/utils/util'
import {Dialog} from 'vant';
import Notify from "vant/es/notify";

export default {
  data() {
    return {
      img: require('../assets/images/pic_default.jpg'),
      showPicker: false,
      checked: false,
      dateValue: '/　　/',
      showCalendar1: false,
      productBundleOptions: [],
      productBundle: {},
      extraMeal: [],
      loading: false,
      type: 'CHANGE',
      advance: {},
      point: {}
    };
  },
  mounted() {
    this.customerUser = Vue.ls.get(ACCESS_CustomerUser)

    this.loading = true
    // let url = 'api/business/v2/{businessId}/product/bundle/{businessCustomerUserId}/search/buy'
    let url = 'api/business/v2/{businessId}/product/bundle/{businessCustomerUserId}/search/reservation'
    url = url.replace('{businessId}', this.customerUser.businessId)
    url = url.replace('{businessCustomerUserId}', this.customerUser.businessCustomerUserId)

    post(url, {}).then(res => {
      if (res.data && res.data.length > 0) {
        res.data.forEach(item => {
          this.productBundleOptions.push({
            id: item.id,
            text: item.name,
            remark: item.remark,
            extraMeal: item.extraMeal,
            singlePrice: item.singlePrice,
            changeCancelAdvance: item.changeCancelAdvance,
            changeTimePoint: item.changeTimePoint,
            addAdvance: item.addAdvance,
            addTimePoint: item.addTimePoint,
          })
        })
        console.log(this.productBundleOptions)
        this.productBundle = this.productBundleOptions[0]
        if (this.productBundle.extraMeal) {
          this.extraMeal = JSON.parse(this.productBundle.extraMeal)
        }
      }
      this.loading = false
    }).catch(() => this.loading = false)

    post('api/business/v2/{businessId}/setting/search'.replace('{businessId}', this.customerUser.businessId), {
      size: 500
    }).then(res => {
      this.advance = res.data.rows.find(item => item.setKey === 'CHANGECANCELADVANCE'); // 换餐提前申请天数
      this.point = res.data.rows.find(item => item.setKey === 'CHANGETIMEPOINT');//
    }).catch(() => {
    });
  },
  methods: {
    onConfirm(value) {
      this.productBundle = value;
      if (this.productBundle.extraMeal) {
        this.extraMeal = JSON.parse(this.productBundle.extraMeal)
      } else {
        this.extraMeal = {}
      }
      this.showPicker = false;
    },
    onConfirm1(date) {
      this.dateValue = `${date.getFullYear()} / ${date.getMonth() + 1} / ${date.getDate()}`;
      this.showCalendar1 = false;
    },
    change() {
      let date = this.dateValue && this.dateValue !== '/　　/' ? parseInt(this.dateValue.split('/')[0]) + '/' + parseInt(this.dateValue.split('/')[1]) + '/' + parseInt(this.dateValue.split('/')[2]) : '';

      if (!date || date === '-　　-') {
        Notify({type: 'warning', message: '请填写报餐日期'});
        return;
      }

      if (!this.productBundle) {
        Notify({type: 'warning', message: '请选择您要操作的餐包'});
        return;
      }

      let productBundleIds = [this.productBundle.id]
      let changeMealStr = null
      if (this.checked) {
        let changeMeal = {}
        changeMeal.name = this.productBundle.remark;
        changeMeal.singlePrice = this.productBundle.singlePrice;
        changeMeal.count = 1
        changeMealStr = JSON.stringify(changeMeal)
      }

      let extraMealStr = null
      if (this.extraMeal && this.extraMeal.length > 0) {
        let submitExtraMeal = []
        this.extraMeal.forEach(item => {
          if (item.count > 0) {
            submitExtraMeal.push(item)
          }
        })
        if (submitExtraMeal && submitExtraMeal.length > 0) {
          extraMealStr = JSON.stringify(submitExtraMeal)
        }
      }

      if (!extraMealStr && !changeMealStr) {
        Notify({type: 'warning', message: '请选择换餐与加餐其中一样进行提交'});
        return;
      }

      this.loading = true
      post('api/reservation/v2/change', {
        businessCustomerUserId: this.customerUser.businessCustomerUserId,
        startDay: new Date(date + ' 00:00:00').getTime(),
        endDay: new Date(date + ' 23:59:59').getTime(),
        type: this.type,
        extraMeal: extraMealStr,
        changeMeal: changeMealStr,
        productBundleIds: productBundleIds
      }).then(res => {
        Dialog.alert({message: '提交成功'}).then(() => {
          this.$router.back()
        });
        this.loading = false
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
        this.loading = false
      });

    }
  }
};
</script>
